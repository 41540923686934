import BoxSection from "components/program/box-section"
import Brands from "components/program/brands"
import Carousel from "react-bootstrap/Carousel"
import Container from "react-bootstrap/Container"
import Img from "gatsby-image"
import Layout from "components/layout"
import LocalizedLink from "components/localized-link"
import PropTypes from "prop-types"
import React from "react"
import SEO from "components/seo"
import Top from "components/program/top"
import { graphql } from "gatsby"
import useTranslations from "components/use-translations"

export default function Servishane({ data }) {
  const ekranGoruntuleriNodes = data.ekranGoruntuleri.edges.map(({ node }) => (
    <Carousel.Item key={node}>
      <Img fluid={node.childImageSharp.fluid} />
    </Carousel.Item>
  ))
  const {
    genel: {
      dahaFazlaBilgiMiGerekli,
      ekranGoruntuleri,
      sikSorulanSorular,
      iletisim,
      paketler,
    },
    servishane: {
      seoTitle,
      seoDescription,
      baslik,
      altBaslik,
      altBaslikAlti,
      kullaniciMesaji,
      kullaniciAdi,
      indirButonuYazi,
      butonAltiYazi,
      referansUstuYazi,
      bolumler,
    },
    kutuphane: { bolumler: kutuphaneBolumler },
  } = useTranslations()
  return (
    <Layout>
      <SEO
        title={seoTitle}
        description={seoDescription}
        pathname="https://www.kodvizit.com/servishane"
        datePublished="2020-02-03T21:07:47+00:00"
        dateModified="2020-07-17T16:53:13+00:00"
      />
      <Container>
        <Top
          headerText={baslik}
          headerBottomText={altBaslik}
          useFreeText={altBaslikAlti}
          quoteText={kullaniciMesaji}
          quoteAuthor={kullaniciAdi}
          buttonText={indirButonuYazi}
          buttonLink="http://www.servishane.com/crm"
          buttonBottomText={butonAltiYazi}
          containerStyle={{ paddingBottom: 0 }}
        >
          <Img fluid={data.servishaneBilgisayar.childImageSharp.fluid} />
        </Top>
        <Brands brandText={referansUstuYazi} />
      </Container>

      {/*  1. Firmanızın detaylarını görerek başlayın.*/}
      <BoxSection
        bgLight={true}
        headerText={bolumler[0].baslik}
        descriptionText={bolumler[0].aciklama}
        listItems={bolumler[0].maddeler}
      >
        <Img fluid={data.r_1.childImageSharp.fluid} />
      </BoxSection>

      {/* 2.Kolayca müşterilerinizi kaydedin.*/}
      <BoxSection
        childPositionLeft={true}
        headerText={bolumler[1].baslik}
        descriptionText={bolumler[1].aciklama}
        listItems={bolumler[1].maddeler}
      >
        <Img fluid={data.r_2.childImageSharp.fluid} />
      </BoxSection>

      {/* 3. Raporlayın ve inceleyin*/}
      <BoxSection
        bgLight={true}
        headerText={bolumler[2].baslik}
        listItems={bolumler[2].maddeler}
      >
        <Img fluid={data.r_3.childImageSharp.fluid} />
      </BoxSection>

      {/* 4. Size özel yazılım*/}
      <BoxSection
        childPositionLeft={true}
        headerText={bolumler[3].baslik}
        listItems={bolumler[3].maddeler}
      >
        <Img fluid={data.r_4.childImageSharp.fluid} />
      </BoxSection>

      {/* 5. Verileriniz bizim için önemli*/}
      <BoxSection
        bgLight={true}
        headerText={bolumler[4].baslik}
        listItems={bolumler[4].maddeler}
      >
        <Img fluid={data.r_5.childImageSharp.fluid} />
      </BoxSection>

      {/* 6. Her yere uyuyor*/}
      <BoxSection
        childPositionLeft={true}
        headerText={bolumler[5].baslik}
        listItems={bolumler[5].maddeler}
      >
        <Img fluid={data.r_6.childImageSharp.fluid} />
      </BoxSection>

      {/* 7. Gizlilik ve güvenlik gerekli*/}
      <BoxSection
        bgLight={true}
        headerText={bolumler[6].baslik}
        listItems={bolumler[6].maddeler}
      >
        <Img fluid={data.r_7.childImageSharp.fluid} />
      </BoxSection>

      {/* 8. Sadelik ve basitlik*/}
      <BoxSection
        childPositionLeft={true}
        headerText={bolumler[7].baslik}
        listItems={bolumler[7].maddeler}
      >
        <Img fluid={data.r_8.childImageSharp.fluid} />
      </BoxSection>

      {/* 7. Gizlilik ve güvenlik gerekli*/}
      <BoxSection
        bgLight={true}
        headerText={bolumler[8].baslik}
        listItems={bolumler[8].maddeler}
      >
        <Img fluid={data.r_9.childImageSharp.fluid} />
      </BoxSection>

      {/* 8. Sadelik ve basitlik*/}
      <BoxSection
        childPositionLeft={true}
        headerText={bolumler[9].baslik}
        listItems={bolumler[9].maddeler}
      >
        <Img fluid={data.r_10.childImageSharp.fluid} />
      </BoxSection>

      {/* 7. Gizlilik ve güvenlik gerekli*/}
      <BoxSection
        bgLight={true}
        headerText={bolumler[10].baslik}
        listItems={bolumler[10].maddeler}
      >
        <Img fluid={data.r_11.childImageSharp.fluid} />
      </BoxSection>

      {/* 8. Sadelik ve basitlik*/}
      <BoxSection
        childPositionLeft={true}
        headerText={bolumler[11].baslik}
        listItems={bolumler[11].maddeler}
      >
        <Img fluid={data.r_12.childImageSharp.fluid} />
      </BoxSection>

      {/* 7. Gizlilik ve güvenlik gerekli*/}
      <BoxSection
        bgLight={true}
        headerText={bolumler[12].baslik}
        listItems={bolumler[12].maddeler}
      >
        <div style={{ maxHeight: "100%", height: "350px" }}>
          <Img
            fluid={data.r_13.childImageSharp.fluid}
            imgStyle={{ objectFit: "contain" }}
            style={{ maxHeight: "100%" }}
          />
        </div>
      </BoxSection>

      {/* 9. Kendiniz inceleyin*/}
      <BoxSection
        bgLight={true}
        childPositionLeft={true}
        headerText={bolumler[13].baslik}
        descriptionText={bolumler[13].aciklama}
      >
        <div style={{ height: "500px" }}>
          <Carousel>{ekranGoruntuleriNodes}</Carousel>
        </div>
      </BoxSection>

      {/* 10. Her zaman yanınızdayız*/}
      <BoxSection
        childPositionLeft={true}
        headerText={kutuphaneBolumler[9].baslik}
        descriptionText={kutuphaneBolumler[9].aciklama}
        listItems={kutuphaneBolumler[9].maddeler}
      >
        <div style={{ maxHeight: "100%", height: "300px" }}>
          <Img
            fluid={data.destek.childImageSharp.fluid}
            imgStyle={{ objectFit: "contain" }}
            style={{ height: "100%" }}
          />
        </div>
      </BoxSection>

      {/* 10. Kurumsalız*/}

      <BoxSection
        bgLight={true}
        headerText={kutuphaneBolumler[10].baslik}
        descriptionText={kutuphaneBolumler[10].aciklama}
        listItems={kutuphaneBolumler[10].maddeler}
      >
        <div style={{ maxHeight: "100%", height: "300px" }}>
          <Img
            fluid={data.kurumsal.childImageSharp.fluid}
            imgStyle={{ objectFit: "contain" }}
            style={{ height: "100%" }}
          />
        </div>
      </BoxSection>

      <BoxSection containerStyle={{ paddingTop: 0 }}>
        <h5 className="text-center">{dahaFazlaBilgiMiGerekli}</h5>

        <ul className="list-unstyled text-center m-0" style={{ fontSize: 16 }}>
          <li className="mb-0">
            <LocalizedLink to="/servishane/paketler">{paketler}</LocalizedLink>
          </li>
          <li className="mb-0">
            <LocalizedLink to="/servishane/ekran-goruntuleri">
              {ekranGoruntuleri}
            </LocalizedLink>
          </li>
          <li className="mb-0">
            <LocalizedLink to="/servishane/sik-sorulan-sorular">
              {sikSorulanSorular}
            </LocalizedLink>
          </li>
          <li className="mb-0">
            <LocalizedLink to="/iletisim/">{iletisim}</LocalizedLink>
          </li>
        </ul>
      </BoxSection>
    </Layout>
  )
}

export const query = graphql`
  query {
    servishaneBilgisayar: file(
      relativePath: { eq: "servishane/servishane-bilgisayar.png" }
    ) {
      childImageSharp {
        fluid(maxWidth: 800, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    r_1: file(relativePath: { eq: "servishane/ekran-goruntuleri/1.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 800, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    r_2: file(relativePath: { eq: "servishane/ekran-goruntuleri/2.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 800, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    r_3: file(relativePath: { eq: "servishane/ekran-goruntuleri/3.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 800, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    r_4: file(relativePath: { eq: "servishane/ekran-goruntuleri/4.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 800, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    r_5: file(relativePath: { eq: "servishane/ekran-goruntuleri/5.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 800, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    r_6: file(relativePath: { eq: "servishane/ekran-goruntuleri/6.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 800, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    r_7: file(relativePath: { eq: "servishane/ekran-goruntuleri/7.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 800, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    r_8: file(relativePath: { eq: "servishane/ekran-goruntuleri/8.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 800, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    r_9: file(relativePath: { eq: "servishane/ekran-goruntuleri/9.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 800, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    r_10: file(relativePath: { eq: "servishane/ekran-goruntuleri/10.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 800, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    r_11: file(relativePath: { eq: "servishane/ekran-goruntuleri/11.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 800, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    r_12: file(relativePath: { eq: "servishane/ekran-goruntuleri/12.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 800, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    r_13: file(relativePath: { eq: "servishane/ekran-goruntuleri/13.jpg" }) {
      childImageSharp {
        fluid(maxHeight: 350, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    destek: file(relativePath: { eq: "kutuphane/destek.png" }) {
      childImageSharp {
        fluid(maxHeight: 400, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    kurumsal: file(relativePath: { eq: "kutuphane/aydemirler.png" }) {
      childImageSharp {
        fluid(maxHeight: 400, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    ekranGoruntuleri: allFile(
      filter: { relativePath: { regex: "/^servishane/ekran-goruntuleri/" } }
    ) {
      edges {
        node {
          childImageSharp {
            fluid(maxHeight: 550) {
              ...GatsbyImageSharpFluid_withWebp_noBase64
              ...GatsbyImageSharpFluidLimitPresentationSize
            }
          }
        }
      }
    }
  }
`

Servishane.propTypes = {
  data: PropTypes.object,
}
